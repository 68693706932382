import { createApp, h, VueConstructor  } from 'vue'

export default function RenderVueComponentAsString(component : VueConstructor, props : {}) {
    const tempApp = createApp({
        render() {
            return h(component, props);
        }
    });

    const tempElement = document.createElement('div');
    const mountedTempApp = tempApp.mount(tempElement)

    const res = mountedTempApp.$el.outerHTML;
    tempApp.unmount();
    return res;
}